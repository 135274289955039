import React from 'react'
import cn from './subcontainer.module.css'
import XMasCounter from '../XMasCounter/XMasCounter'

const Subcontainer = (props) => {
    return (
        <div className={cn.mainContainer}>
            <div className={cn.lent}>
                <div className={cn.foxkidsLogo}>

                </div>
                {/* <XMasCounter/> */}
                <div className={cn.digimonLogo}></div> 
                <div className={cn.pokemonLogo}></div>
                {/* <div className={cn.luieImg}></div> */}
                {/* <div className={cn.luieLogo}></div> */}
                <div className={cn.ashImg}></div>
                
            </div>

            <div className={cn.childContainer}>
                {props.children}
            </div>

        </div>
    )
}

export default Subcontainer