import React, { useState } from 'react'
import cn from './footer.module.css'
import QuiwiDonate from '../QuiwiDonate/QuiwiDonate'
import ReactModal from 'react-modal'
const Footer = (props) => {
    const [donate, setDonate] = useState(false)
    const openModal = () => {
        setDonate(true)
    }
    const closeModal = () => {
        setDonate(false)
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay: {
            backgroundColor: 'rgb(19, 19, 19, 0.6)'
        }
    };
    return (
        <div className={cn.footerContainer}>
            <div className={cn.footerSubcontainer}>
                <div className={cn.subontainer}>
                    <div onClick={openModal} className={cn.dontateContainer}>
                        <img className={cn.donateIcon}
                            src={require('../../media/site/donation.png')} />
                        <div className={cn.donateLabel}>ПОЖЕРТВОВАТЬ</div>
                    </div>
                    <div>
                        <div>ПРЯМАЯ ТРАНСЛЯЦИЯ FOXKIDS</div>
                        <div>Обратная связь с разработчиком:</div>
                        <div>✉TV@FOXKIDS.ONLINE</div>
                    </div>
                </div>
                <div className={cn.socialMediaContainer}>
                    <a href='https://www.youtube.com/channel/UCGS54fun3WPu8Xn7ZM5VQOA'>
                        <img className={cn.socialMediaIcon} src={require('../../media/site/youtube.png')} />
                    </a>
                    <a href='https://t.me/foxkids_online'>
                        <img className={cn.socialMediaIcon} src={require('../../media/site/telegram.png')} />
                    </a>
                    <a href='https://vk.com/foxkids.online'>
                        <img className={cn.socialMediaIcon} src={require('../../media/site/vk.png')} />
                    </a>
                    <ReactModal
                        style={customStyles}
                        isOpen={donate}
                        onRequestClose={closeModal}>
                        <QuiwiDonate />
                    </ReactModal>
                </div>
            </div>
        </div>
    )
}

export default Footer